import Quill from "quill";
// 源码中是import直接倒入，这里要用Quill.import引入
const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')

const ATTRIBUTES = ['height', 'width']

class Video extends BlockEmbed {
  static create(value) {
    let node = super.create()
    //添加
    node.setAttribute('src', value.url)
    node.setAttribute('controls', value.controls)
    node.setAttribute('width', "100%")
    node.setAttribute('height', value.height)
    node.setAttribute('type', "video/mp4")
    // node.setAttribute('muted',true)
    // node.setAttribute('loop', "loop")
    // node.setAttribute('autoplay', "autoplay")
    node.setAttribute('x5-video-player-type','h5')
    node.setAttribute('playsinline',true)
    node.setAttribute('webkit-playsinline',true)
  
    return node
  }

  static formats (domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }

  static sanitize (url) {
    return Link.sanitize(url)
  }

  static value (domNode) {
    // 设置值包含宽高，为了达到自定义效果
    //宽高为空的话，就是按100%算
    return {
      url: domNode.getAttribute('src'),
      controls: domNode.getAttribute('controls'),
      width: domNode.getAttribute('width'),
      height: domNode.getAttribute('height'),
      type:"video/mp4",

      
    }
  }

//   http://oss.fuhangbeiyue.com/video/cat2_1654927023847.mp4
  format (name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }

  html () {
    const { video } = this.value()
    return `<a href="${video}">${video}</a>`
  }
}
Video.blotName = 'video'
Video.className = 'ql-video' // 可添加样式，看主要需要
Video.tagName = 'video' // 用video标签替换iframe

export default Video